@import "../../../styles/variables";
.canvas-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: $black-bg-color;
}
.canvas-main {
  display: grid;
  grid-template-columns: 238px 1fr 23%;
  grid-template-rows: calc(100vh - 125px);
}
.canvas-background {
  display: grid;
  grid-template-rows: 100px 60px 1fr;
  padding-top: 1em;
  &::before,
  &::after {
    display: none;
  }
}

.introTooltipClass {
  font-size: 15px;
}
