html {
  overflow: hidden;
  scroll-behavior: smooth;
}

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  /* background: #88888880;
  border-radius: 16px; */

  background: rgba(112, 112, 112, 0.501961);
  mix-blend-mode: normal;
  opacity: 0.75;
  border-radius: 29px;
  transform: rotate(90deg);
}

.focused-item {
  outline: 1px solid white;
}

.focus-outline:focus {
  outline: 1px solid white;
}

/* ant Table vertical scroll bar  */
.ant-table-body::-webkit-scrollbar {
  width: 12px;
  height: 14px;
}

.ant-table {
  background: none;
}

.App {
  /* Global CSS goes here */
  background-color: #f1f3f6;
  /* height: calc(100vh); */
}

.App > main {
  background-color: #f1f3f6;
  /* margin-top: 60px; */
}

.ia-container-wrapper {
  margin: 3px 10px;
}

.html-symbol {
  cursor: pointer;
}

.ant-popover-inner-content {
  padding: 6px 16px !important;
}

.ia-card-like {
  background: white;
  border-radius: 3px;
  border: 1px solid rgba(204, 204, 204, 0.5);
  box-shadow: 3px 3px 9px 0 #cccccc;
}

/* Hide Date showed in text format for date picker */
.ant-calendar-input-wrap,
.ant-calendar-range-middle,
.ant-calendar-picker-input .anticon-close-circle,
.ant-calendar-picker-clear {
  display: none;
}

/* Hide next and previous year button of date picker */
.ant-calendar-prev-year-btn,
.ant-calendar-next-year-btn {
  visibility: hidden;
}

/* Hide selection on Today date */
.ant-calendar-today .ant-calendar-date {
  color: black;
  font-weight: normal;
  border-color: white;
}

/* Fixed table header height becase de-select all col makin issue with height of header */
.ant-table-fixed-columns-in-body,
.ant-table-row-cell-break-word,
.ant-table-column-sorters {
  height: 42px;
}

.view-claim-button {
  width: 108px !important;
  height: 34px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.160784) !important;
  border-radius: 29px !important;
  color: white !important;
  padding: 0.2rem 0.75rem !important;
}

.view-duplicate-claim-button {
  width: 127px !important;
}

.reviewer-menu {
  max-width: 308px;
  max-height: 353px;
  overflow-y: scroll;
}

/* .reviewer-menu div  ul li {
  border: 2px solid salmon;
} */

.review-comment-button {
  color: gray !important;
  width: 100px !important;
  font-size: 12px !important;
  height: 35px;
  border-radius: 12px !important;
}

.review-comment-button > svg {
  margin-top: -2px;
  margin-left: 2px;
  font-size: 18px;
}

button.btn.add::before {
  font-family: fontAwesome;
  content: "\f067\00a0";
}

.OJ-review-button {
  width: 100px !important;
}

/* .text-center {
  text-align: center;
} */
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.oj-search-container {
  padding-top: 27px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.oj-search-container .ant-input {
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid rgba(0, 0, 0, 0.36);
}

@media screen and (max-height: 850px) {
  .oj-search-container {
    padding-top: 12px;
  }
}

.ant-checkbox .ant-checkbox-inner {
  width: 22px !important;
  height: 22px !important;
}

th.oj-table-th-center span {
  max-width: 58% !important;
}

.oj-page-container {
  padding: 25px 80px 47px 70px;
}

.content-area {
  background-color: #f2f3f7;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-link-highlight,
.no-link-highlight:hover,
.no-link-highlight:active,
.no-link-highlight:focus {
  color: #000;
  text-decoration: none;
}

.checkbox-grid,
.checkbox-grid-procedure-type {
  display: flex;
  flex-direction: column;

  .ant-checkbox-wrapper {
    color: #000;
  }

  .checkbox-column {
    display: flex;
    font-size: 14px;
    line-height: 30px;
    justify-content: flex-start;
    align-items: center;
    padding-right: 4px;

    .ant-form-item-control {
      line-height: 30px;
    }

    :not(:first-child) {
      padding-top: 5px;
    }

    .ant-checkbox .ant-checkbox-inner {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

.ant-result {
  &.success {
    .anticon {
      color: #2e6e0f;
    }
  }

  &.error {
    .anticon {
      color: #8c2727;
    }
  }
}

.ant-select .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.87);
}

.panel-table {
  .ant-table-placeholder {
    background: none;
    border: none;

    .ant-empty-description {
      color: #fff;
    }

    &:hover td {
      background: none !important;
    }
  }

  .ant-table-tbody td,
  .ant-table-thead th {
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    border: none;
    padding-bottom: 2px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 8px;
    }
  }

  .ant-table-thead {
    th {
      padding: 0;
      background: none;
      font-weight: bold;
      border-bottom: 2px solid #393f4e;
    }
    tr.focused-heading th {
      border-bottom: 1px solid #fff;
    }
  }

  &.no-border-on-headers {
    .ant-table-thead {
      th {
        border-bottom: none;
      }
    }
  }

  .ant-table-tbody tr.focused-row {
    td {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
    td:first-child {
      border-left: 1px solid #fff;
    }
    td:last-child {
      border-right: 1px solid #fff;
    }
  }

  .ant-table-tbody {
    td {
      border: none;
      padding: 4px 0 0 0;
    }

    tr.ant-table-row:hover td {
      background: none;
    }
  }
}
