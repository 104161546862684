body {
  background: darkgrey;
  margin: 0;
  font-size: 10px;
  font-family: "SF Pro Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/0b51833ff62e4af8acb5fd3e2bf59e97.eot"); /* IE9*/
  src:
    url("./assets/fonts/0b51833ff62e4af8acb5fd3e2bf59e97.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/0b51833ff62e4af8acb5fd3e2bf59e97.woff2")
      format("woff2"),
    /* chrome firefox */
      url("./assets/fonts/0b51833ff62e4af8acb5fd3e2bf59e97.woff") format("woff"),
    /* chrome firefox */
      url("./assets/fonts/0b51833ff62e4af8acb5fd3e2bf59e97.ttf")
      format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url("./assets/fonts/0b51833ff62e4af8acb5fd3e2bf59e97.svg#SF Pro Display")
      format("svg"); /* iOS 4.1- */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.narrative-modal .hightlight {
  background-color: yellow;
}

.align-center {
  justify-content: center !important;
}
